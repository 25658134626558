<template>
  <WsMain>
    <ValidationObserver ref="form">
      <form
        ref="createForm"
        @submit.prevent
      >
        <WsState
          :label="$t('數量')"
          class="mt-20"
          type="number"
          v-model="state.count"
        ></WsState>
        <WsState
          type="belongsTo"
          :label="$t('服務方案')"
          v-model="state.service_plan"
          rules="required"
          textKey="name"
          modelName="service_plan"
        ></WsState>
        <WsState
          type="date"
          label="開卡期限"
          v-model="state.expired_at"
        ></WsState>
      </form>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_PinCard from "@/__vue2stone_cms/service/api/v1/pin_card";
export default {
  metaInfo() {
    return {
      title: `${this.$t("PIN 產生")}`,
    };
  },

  data() {
    return {
      modelName: "pin_card",
      fields: this.$store.state.stone_model.pin_card.fields,
      state: {
        count: 100,
        service_plan: null,
        expired_at: null,
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      const postData = {
        count: this.state.count,
        expired_at: this.state.expired_at,
        service_plan: this.state.service_plan
          ? this.state.service_plan.id
          : null,
      };
      try {
        this.loading.submit = true;
        await S_PinCard.generate(postData);
        this.$router.push("/pin_card");
      } catch (error) {
        alert("Generate Error.");
      } finally {
        this.loading.submit = false;
      }
    },
  },
};
</script>

<style>
</style>